import { Container, Grid } from '@mui/material'
import { TronContext } from 'contexts/TronContext'
import React, { useContext } from 'react'
import styled from 'styled-components'
import ConnectModal from './ConnectModal'
import LoginPage from './LoginPage'

const Homepage = () => {
    const { isConnected } = useContext(TronContext)
    console.log({ isConnected })
    return isConnected ? (
        <Wrap>
            <Container maxWidth="xl">
                <LoginPage />
            </Container>
        </Wrap>
    ) :
        (
            <Wrap>
                <Container maxWidth="xl">
                    <ConnectModal />
                </Container>
            </Wrap>
        )
}

export default Homepage
const Wrap = styled.div`
    

`