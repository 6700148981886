// eslint-disable-next-line import/no-anonymous-default-export
export default {
    RLS000: "UNEXPECTED ERROR",
    RLS100: "SIGNATURE INVALID",
    RLS101: "AUTHORIZATION REQUIRED",
    RLS102: "HEADER ADDRESS REQUIRED",
    RLS103: "ADMIN REQUIRED",
    RLS104: "SIGNATURE OUTDATE",

    RLS200: "SIGNATURE INVALID",
    RLS201: "DOMAIN NOT EXIST",
    RLS202: "MUST APPROVE FIRST",
    RLS203: "INSUFFICIENT WTRX",
    RLS204: "INVALID API KEY",
    RLS205: "INSUFFICIENT BALANCE",
    RLS206: "INVALID PAYMENT METHOD",

    RLS212: "ADDRESS INVALID",
    RLS213: "DURATION INVALID",

    RLS400: "MISSING PARAMS",
    RLS401: "INVALID PAGE",
    RLS402: "INVALID PAGESIZE",
    RLS403: "INVALID VALID UNTIL TIME",
    RLS404: "INVALID ERC20 ADDRESS",
    RLS405: "INVALID VALUE",
    RLS406: "USER NOT EXIST",
    RLS407: "BALANCE IS NOT ENOUGH",
    RLS408: "INVALID PARAMS",
    RLS409: "PROJECT NOT EXISTS",
    RLS410: "PROJECT NAME EXISTS",
    RLS411: "TRANSACTION IS NOT TRANSFER TRX",
    RLS412: "TRANSACTION IS NOT VALID TRANSFER TRX",
    RLS413: "RATE LIMIT",
    RLS414: "MIN TRANSFER AMOUNT",
    RLS415: "ABI_INVALID",
    RLS416: "RECIPIENT CONTRACT NOT EXISTS",
    RLS417: "RECIPIENT CONTRACT ALREADY EXISTS",
    RLS418: "RECIPIENT CONTRACT INVALID",
    RLS501: "SERVER MAINTAINED",
}