// component

import SvgColor from "components/core/SvgColor";


// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
    // {
    //     title: 'Home',
    //     path: '/',
    //     // icon: icon('ic_analytics'),
    // },
    {
        title: 'dashboard',
        path: '/dashboard',
        icon: icon('ic_analytics'),
    },
    {
        title: 'account',
        path: '/account',
        icon: icon('ic_user'),
    },
    // {
    //   title: 'performance',
    //   path: '/dashboard/performance',
    //   icon: icon('ic_user'),
    // },
    // {
    //   title: 'user',
    //   path: '/dashboard/user',
    //   icon: icon('ic_user'),
    // },
    // {
    //   title: 'product',
    //   path: '/dashboard/products',
    //   icon: icon('ic_cart'),
    // },
    // {
    //   title: 'blog',
    //   path: '/dashboard/blog',
    //   icon: icon('ic_blog'),
    // },
    // {
    //   title: 'login',
    //   path: '/login',
    //   icon: icon('ic_lock'),
    // },
    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: icon('ic_disabled'),
    // },
];

export default navConfig;
