import { toast } from 'react-toastify';
interface NotifyToastifyProps {
    type: "success" | "error" | "info" | "warning",
    text: string;
    duration?: number
}
export const notifyToastify = ({ type, text, duration = 5000 }: NotifyToastifyProps) => {
    switch (type) {
        case "success": {
            toast.success(text, {
                position: "top-right",
                autoClose: duration,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
            break;
        }
        case "error": {
            toast.error(text, {
                position: "top-right",
                autoClose: duration,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;
        }
        case "warning": {
            toast.warning(text, {
                position: "top-right",
                autoClose: duration,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;
        }

        default: {
            toast.info(text, {
                position: "top-right",
                autoClose: duration,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;
        }
    }
    return null;
}

