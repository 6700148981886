import React, { useContext, useEffect } from "react";
import "./App.css";
import styled from "styled-components";
import { Button, Container } from "@mui/material";
import Router from "routers";
import ThemeProvider from "./theme";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Cookies } from "react-cookie";
import { TronContext } from "contexts/TronContext";
import ConnectModal from "pages/ConnectModal";
import ReloadContextWrap from "contexts/ReloadContext";
import { GlobalStyle } from "constanst/globalStyles";
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    action: { address },
    isConnected,
  } = useContext(TronContext);
  useEffect(() => {
    const Cookie = new Cookies();
    if (isConnected) {
      if (Cookie.get(`token-${address}`)) {
      } else {
        navigate("/login");
      }
    }
  }, [location.pathname, address]);
  return (
    <ThemeProvider>
      <GlobalStyle />
      <Wrap>
        {!isConnected && (
          <Wrap>
            <Container maxWidth="xl">
              <ConnectModal />
            </Container>
          </Wrap>
        )}

        <ReloadContextWrap>
          <Router />
        </ReloadContextWrap>

        <ToastContainer />
      </Wrap>
    </ThemeProvider>
  );
}

export default App;
const Wrap = styled.div`
  min-height: 100vh;
`;
