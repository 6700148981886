import { useQuery } from '@apollo/client'
import { QUERY_USER_INFO } from 'api/schema'
import { formatSunToTRX } from 'helpers/format/formatNumber'
import React, { useMemo } from 'react'

const useBalanceOffchain = () => {
    const { data, loading, error } = useQuery(QUERY_USER_INFO)
    const balance = useMemo(() => {
        if (data && data.rls_user_info_get) {
            const { balance } = data.rls_user_info_get
            return balance
        }
        return 0
    }, [data])
    return { balance, balanceFormat: formatSunToTRX(balance) }
}

export default useBalanceOffchain