import BigNumber from 'bignumber.js';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number) {
    return numeral(number).format();
}

export function formatCustom(number, type="0,0.[00]") {
    return numeral(number).format(type, Math.floor);
}

export function fCurrency(number) {
    const format = number ? numeral(number).format('$0,0.00') : '';

    return result(format, '.00');
}

export function fPercent(number) {
    const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

    return result(format, '.0');
}

export function fShortenNumber(number) {
    const format = number ? numeral(number).format('0.00a') : '0';

    return result(format, '.00');
}

export function fData(number) {
    const format = number ? numeral(number).format('0.0 b') : '';

    return result(format, '.0');
}
export function formatSunToTRX(number) {
    const format = number ? new BigNumber(number).div(1e6).toNumber() : 0;
    return format;
}
export function formatTrxToSun(number) {
    const format = new BigNumber(number).multipliedBy(1e6).toNumber()
    return format;
}


function result(format, key = '.00') {
    const isInteger = format.includes(key);

    return isInteger ? format.replace(key, '') : format;
}
