
export const formatStringAddress = (e: string, length: number = 3) => {
    if (e === null) {
        return ''
    }
    if (e.length < length) {
        return e
    }
    return e.substring(0, length) + "..." + e.substr(-length)
}
