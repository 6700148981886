import { createGlobalStyle } from "styled-components";
import { breakpointsMedias } from "./breakpointMedias";
import { configColor } from "./configColor";
export const GlobalStyle = createGlobalStyle`
  :root{
    font-size: 16px;
  }
  .container{
    width:100%;
    padding: 0 40px;
    margin-left:auto;
    margin-right:auto;
    max-width: 1616px;
      &.full{
        max-width:100vw;
      }
  }
  .bg-animation{
    position:fixed;
  }
  .margin-section-home{
    margin-bottom:60px;
  }
  .cl-primary{
    color: #fff
  }
  .bold{
    font-weight:bold;
  }
  .center{
    text-align:center;
  }
  .page-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-top: 32px;
        border-radius: 4px;
        /* background: linear-gradient(270deg, #FFBEFA 0%, #AF47D3 48.96%, #4E1BC1 100%); */
        .pc-item {
          margin: 0 6px;
          text-align: center;
          > a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 0;
            min-width: 42px;
            color: ${configColor.colorWhite};
            background-color: #FFFFFF0F;
            border-radius: 4px;
          }
          &:hover {
            > a {
              background-color: #ffffff46;
            }
          } 
        }
        .pc-item-active {
          > a {
              background-color: ${configColor.green2} !important;
          }
        }
        .pc-prev,
        .pc-next {
          color: white;
          text-align: center;
          margin: 0 18px;
          > a {
            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 8px 12px;
              min-width: 42px;
              height: 42px;
              color: ${configColor.colorWhite};
              background-color: #FFFFFF0F;
              border-radius: 4px;
            }
          }
          &.disabled {
            > a {
              > div {
                opacity: 0.5;
                cursor: not-allowed;
              }
            }
          }
        }
        .pc-prev:hover,
        .pc-next:hover {
          > a {
            > div {
              background-color: #ffffff46;
            }
          }
          &.disabled {
            > a {
              > div {
                background-color: #FFFFFF0F;
              }
            }
          }
        }
        .break {
          margin: 0 6px;
          text-align: center;
          > a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 0;
            min-width: 42px;
            height: 42px;
            color: ${configColor.colorWhite};
            background-color: #FFFFFF0F;
            border-radius: 4px;
          }
          &:hover {
            > a {
              background-color: #ffffff46;
            } 
          }
        }
    }
    .scrollbar {
    scrollbar-width: thin;
    &::-webkit-scrollbar {
            width: 6px !important;
            height: 6px !important;
            margin-top:10px;
        }
        &::-webkit-scrollbar-track {
            background: transparent !important;
            border-radius: 6px !important;
        }
        &::-webkit-scrollbar-thumb {
            background: ${configColor.green} !important;
            border-radius: 6px !important;
        }
  }
  ${breakpointsMedias.max1199}{
    .container{
      padding: 0 24px;
    }
  }
  ${breakpointsMedias.max767}{
    .container{
      padding: 0 16px;
    }
  }
  ${breakpointsMedias.max490}{
    .container{
      /* padding: 0 14px; */
    }
  }
  .MuiDialog-paper {
    /* border: 1px solid red; */
    padding: 32px 16px; 
   /* :before{
    content: "";
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background: linear-gradient(
      -45deg,
      #8cf5a8 0%,
      #c1ffd210 50%,
      #8cf5a8 100%
    );
    position: absolute;
    left: -1px;
    top:-1px;
    z-index: 0;
    overflow: hidden;
   } */
  }
  .MuiCard-root{
    border: 1px solid rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(20px);
  }
  //remove background modal bacdrop
  .MuiModal-backdrop{
    background-color: transparent;
  }
  .layout-grid{
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background: var(--secondary-bg-modal, rgba(45, 49, 46, 0.8));
    backdrop-filter: blur(20px);
    padding: 32px 24px;
    ${breakpointsMedias.max767}{
      padding:16px;
    }
  }
  .MuiTableCell-root{
    border-color:#484848;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
            width: 6px !important;
            height: 6px !important;
            margin-top:10px;
        }
        &::-webkit-scrollbar-track {
            background: transparent !important;
            border-radius: 6px !important;
        }
        &::-webkit-scrollbar-thumb {
            background: ${configColor.green} !important;
            border-radius: 6px !important;
        }
  }
  .MuiTableContainer-root{
    scrollbar-width: thin;
    &::-webkit-scrollbar {
            width: 6px !important;
            height: 6px !important;
            margin-top:10px;
        }
        &::-webkit-scrollbar-track {
            background: transparent !important;
            border-radius: 6px !important;
        }
        &::-webkit-scrollbar-thumb {
            background: ${configColor.green} !important;
            border-radius: 6px !important;
        }
  }
  .MuiList-root-MuiMenu-list{
    background-color: #484848;
  }
  .MuiDialogContent-root{
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
        margin-top:10px;
    }
    &::-webkit-scrollbar-track {
        background: transparent !important;
        border-radius: 6px !important;
    }
    &::-webkit-scrollbar-thumb {
        background: ${configColor.green} !important;
        border-radius: 6px !important;
    }
  }
  .MuiDialog-container{
    .MuiPaper-root{
      position: relative;
      *{
        z-index: 5;
      }
      :before{
        content: "";
        width: calc(100% );
        height: calc(100% );
        background: linear-gradient(-20deg,rgb(140,245,168) 0%,rgba(193,255,210,0.063) 50%,rgb(140,245,168) 100%);
        position: absolute;
        left: 50%;
        top:50%;
        transform: translate(-50%,-50%);
        z-index: 0;
        overflow: hidden;
      }
      :after{
        content: "";
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: #ccc;
        position: absolute;
        left: 50%;
        top:50%;
        transform: translate(-50%,-50%);
        z-index: 0;
        overflow: hidden;
        background: #2D312E;
        border-radius: 6px;
      }
    }
  }
`;
