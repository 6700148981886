import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TronContextWrap from './contexts/TronContext';
import Apollo from './contexts/Apollo';
import { BrowserRouter } from 'react-router-dom';
import AuthUserContextWrap from 'contexts/AuthUserContext';
import { CookiesProvider } from 'react-cookie';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <CookiesProvider>
      <TronContextWrap>
        <Apollo>
          <AuthUserContextWrap>
            <App />
          </AuthUserContextWrap>
        </Apollo>
      </TronContextWrap>
    </CookiesProvider>
  </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
