import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Grid,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { MUTATION_ADD_WHITELIST_CONTRACT } from "api/schema";
import LoadingScreen from "components/core/LoadingScreen";
import { splitError } from "helpers/format/splitError";
import { notifyToastify } from "helpers/toastNotify";
import React, { useEffect, useMemo, useRef, useState } from "react";
import TronWeb from "tronweb";
import DetailDappSettingContract from "./DetailDappSettingContract";
import NextStep from "components/core/ButtonStep";
interface IDetailDappSettings {
  apiKey: string;
}
interface IAddWhitelist {
  apiKey: string;
  contractName: string;
  contractAddress: string;
  contractAbi: string;
}
const DetailDappSettings = ({ apiKey }: IDetailDappSettings) => {
  const initInfoWhitelist = {
    contractId: "",
    contractName: "",
    contractAddress: "",
    contractAbi: "",
  };
  const [infoWhitelist, setInfoWhitelist] = useState(initInfoWhitelist);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInfoWhitelist({
      ...infoWhitelist,
      [event.target.name]: event.target.value,
    });
  };
  const { contractId, contractName, contractAddress, contractAbi } =
    infoWhitelist;
  const [onAddContract, { data, loading, error }] = useMutation(
    MUTATION_ADD_WHITELIST_CONTRACT
  );

  const onAddWhitelist = ({
    apiKey,
    contractAbi,
    contractAddress,
    contractName,
  }: IAddWhitelist) => {
    if (!apiKey) {
      return;
    }
    if (!contractAbi) {
      notifyToastify({ type: "error", text: "Contract abi is required" });
      return;
    }
    if (!contractAddress) {
      notifyToastify({ type: "error", text: "Contract address is required" });
      return;
    }
    if (!contractName) {
      notifyToastify({ type: "error", text: "Contract name is required" });
      return;
    }
    if (!contractName) {
      notifyToastify({ type: "error", text: "Contract name is required" });
      return;
    }
    if (!contractAbi?.startsWith("[{")) {
      notifyToastify({ type: "error", text: "Abi isn't correct type abi" });
      return;
    }
    try {
      onAddContract({
        variables: {
          apiKey,
          contractName,
          contractAbi: contractAbi,
          contractAddress,
        },
      }).then(
        () => {
          notifyToastify({
            type: "success",
            text: "Added contract successfully",
          });
          setInfoWhitelist(initInfoWhitelist);
        },
        (error) => {
          console.log("err", error);
          notifyToastify({ type: "error", text: splitError(error) });
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (contractAbi) {
      // console.log(typeof contractAbi, contractAbi.trim())
      // const listMethod = convertMethodAbi(JSON.stringify(contractAbi))
      // console.log({ listMethod })
    }
  }, [contractAbi]);
  //  multi selection
  const names = ["Oliver Hansen", "Van Henry"];

  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const refContractName = useRef<HTMLDivElement | null>(null);
  const [errorForm, setErrorForm] = useState({
    contractName: false,
  });
  const errorContractName = useMemo(() => {
    if (contractName.length === 0) {
      return false;
    }
    if (contractName.length > 30 || contractName.length < 6) {
      return true;
    }
    return false;
  }, [contractName]);
  if (loading) return <LoadingScreen />;
  return (
    <div>
      <div className="layout-grid">
        <Grid container spacing={3}>
          <Grid item={true} xs={12} md={6}>
            <Typography>API KEY:</Typography>
            <Box mt={1} />
            <TextField
              type={"text"}
              variant="outlined"
              fullWidth
              value={apiKey}
              disabled
              placeholder="Enter contract ID"
            />
            <Box mt={2} />
            <Typography>Contract name:</Typography>
            <Box mt={1} />
            <TextField
              type={"text"}
              variant="outlined"
              required
              fullWidth
              name="contractName"
              value={contractName}
              onChange={onChange}
              placeholder="Enter contract ID"
              ref={refContractName}
              error={errorContractName}
              helperText={"Contract name contains 6-30 characters"}
            />
            <Box mt={2} />
            <Typography>Contract address:</Typography>
            <Box mt={1} />
            <TextField
              type={"text"}
              variant="outlined"
              required
              fullWidth
              name="contractAddress"
              value={contractAddress}
              onChange={onChange}
              placeholder="Enter contract ID"
              error={
                contractAddress !== "" && !TronWeb.isAddress(contractAddress)
                  ? true
                  : false
              }
              helperText={
                contractAddress !== "" && !TronWeb.isAddress(contractAddress)
                  ? "Contract address not correct"
                  : false
              }
            />
            <Box mt={2} />
            {/* <Typography>Method name:</Typography>
                <Box mt={1} />
                <FormControl fullWidth>
                    <Select
                        // labelId="demo-multiple-name-label"
                        // id="demo-multiple-name"
                        multiple
                        value={personName}
                        onChange={handleChange}
                    // input={<OutlinedInput label="Name" />}
                    // MenuProps={MenuProps}
                    >
                        {names.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                            //   style={getStyles(name, personName, theme)}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl> */}
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Typography>Contract abi:</Typography>
            <Box mt={1} />

            <TextField
              type={"text"}
              variant="outlined"
              required
              fullWidth
              name="contractAbi"
              value={contractAbi}
              onChange={onChange}
              placeholder={`ex: [{"inputs":"","name":"tron","type":"Function"}]`}
              multiline
              rows={10}
            />
          </Grid>
          <Grid container mt={4} justifyContent="center">
            <NextStep
              text="Add"
              onClick={() =>
                onAddWhitelist({
                  apiKey,
                  contractAbi,
                  contractAddress,
                  contractName,
                })
              }
            />
          </Grid>
        </Grid>
      </div>

      <Grid container mt={6} className="layout-grid">
        <>
          <Typography variant="h5" marginBottom={3}>
            List contracts
          </Typography>
          <DetailDappSettingContract apiKey={apiKey} />
        </>
      </Grid>
    </div>
  );
};

export default DetailDappSettings;
