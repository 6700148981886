import { useMutation } from '@apollo/client'
import { MUTATION_LOGIN } from 'api/schema'
import { splitError } from 'helpers/format/splitError'
import { notifyToastify } from 'helpers/toastNotify'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Cookies, useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import { TronContext } from './TronContext'
interface IOnLogin {
    address: string,
    timestamp: number,
    signature: string
    duration_sec?: number
}
export const AuthContext = createContext({
    isAuth: false,
    onLogin: ({ address, timestamp, signature, duration_sec }: IOnLogin) => { },
    onLogout: () => { }
})
const AuthUserContextWrap = ({ children }) => {
    const { action: { address }, isConnected } = useContext(TronContext)
    const [cookies, setCookie] = useCookies([`token-${address}`]);
    const [isAuth, setIsAuth] = useState(false)
    const navigate = useNavigate()
    const [mutateLogin, { data, loading, error }] = useMutation(MUTATION_LOGIN)
    const onLogin = async ({ address, timestamp, signature, duration_sec = 3600 }: IOnLogin) => {
        if (!address || !signature) {
            return;
        }
        try {
            await mutateLogin({
                variables: {
                    address,
                    timestamp,
                    signature,
                    duration_sec
                }
            }).then(({ data: { rls_login } }) => {
                setIsAuth(true)
                navigate("/dashboard")
                setCookie(`token-${address}`, rls_login, { maxAge: duration_sec })
            }, (error) => {
                notifyToastify({ type: "error", text: splitError(error) })
            })
        } catch (error) {
            console.log("ERROR LOGIN", error)
        }
    }
    const onLogout = async () => {
        const Cookie = new Cookies()
        const token = Cookie.get(`token-${address}`);
        if (token) {
            Cookie.remove(`token-${address}`);
            setIsAuth(false)
            navigate("/login")
        }
    }
    useEffect(() => {
        if (isConnected) {
            const Cookie = new Cookies()
            const token = Cookie.get(`token-${address}`);
            if (token) {
                setIsAuth(true)
            }
        }
    }, [address])
    return (
        <AuthContext.Provider value={{
            isAuth,
            onLogin,
            onLogout
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthUserContextWrap