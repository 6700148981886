import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { MUTATION_TRANSFER_BALANCE } from "api/schema";
import NextStep from "components/core/ButtonStep";
import { ReloadContext } from "contexts/ReloadContext";
import { TronContext } from "contexts/TronContext";
import { formatInput } from "helpers/format/formatInputAmount";
import {
  formatCustom,
  formatSunToTRX,
  formatTrxToSun,
} from "helpers/format/formatNumber";
import { splitError } from "helpers/format/splitError";
import useBalanceOffchain from "helpers/hooks/api-data/useBalanceOffchain";
import { notifyToastify } from "helpers/toastNotify";
import React, { useContext, useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";
interface IDashboardDeposit {
  id: string;
  name: string;
  open: boolean;
  handleClose: () => void;
}
const MIN_TRANSFER = Number(process.env.REACT_APP_TRANSFER) || 1;

const DashboardModalDeposit = ({
  open,
  handleClose,
  id,
  name,
}: IDashboardDeposit) => {
  const { onReload } = useContext(ReloadContext);
  const { action } = useContext(TronContext);
  const { balanceFormat } = useBalanceOffchain();
  const [amount, setAmount] = useState("");
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };
  const [mutationTransfer, { data, loading, error }] = useMutation(
    MUTATION_TRANSFER_BALANCE
  );
  const onTransfer = (from, to, amount) => {
    if (from === "" || to === "" || amount === "") {
      notifyToastify({ type: "error", text: "You must enter full fields" });
      return;
    }
    if (MIN_TRANSFER > formatInput(amount)) {
      notifyToastify({ type: "error", text: "Min transfer is 1TRX" });
      return;
    }
    if (balanceFormat < formatInput(amount)) {
      notifyToastify({ type: "error", text: "Balance not enough" });
      return;
    }
    if (!to) {
      notifyToastify({ type: "error", text: "You must login first" });
      return;
    }
    try {
      mutationTransfer({
        variables: {
          from: from,
          to,
          amount: formatTrxToSun(formatInput(amount)),
        },
      }).then(
        () => {
          notifyToastify({ type: "success", text: "Transfer successfully" });
          setAmount("");
          handleClose();
          onReload();
        },
        (error) => {
          console.log("ERROR TRANSFER: " + error);
          notifyToastify({ type: "error", text: splitError(error) });
        }
      );
    } catch (error) {
      console.log("ERROR TRANSFER TO PROJECT", error);
      handleClose();
    }
  };
  const isDisabled = useMemo(() => {
    if (
      formatInput(amount) <= MIN_TRANSFER ||
      formatInput(amount) > balanceFormat
    ) {
      return true;
    }
    return false;
  }, [amount, balanceFormat]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // sx={{ padding: 10 }}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle
        id="alert-dialog-title"
        justifyContent={"center"}
        fontSize={"2.5rem"}
      >
        Transfer to Dapp
      </DialogTitle>
      <DialogContent>
        {/* <Typography >To *</Typography>
                <Box mt={1} />
                <TextField id="outlined-basic" variant="outlined" required fullWidth value={id}
                    InputProps={{
                        readOnly: true,
                    }}
                /> */}
        <Box mt={1} />
        <Typography>Amount (TRX) </Typography>
        <Box mt={1} />
        {/* <TextField type={"number"} variant="outlined" required fullWidth value={amount} onChange={onChange} /> */}
        <FormControl fullWidth>
          <NumericFormat
            customInput={TextField}
            value={amount}
            variant="outlined"
            thousandSeparator={true}
            onChange={onChange}
            autoComplete="off"
            width={"100%"}
            placeholder="Enter amount"
          />
        </FormControl>

        <Box mt={1} />

        <Typography fontSize={12} style={{ fontStyle: "italic" }}>
          Balance: <span>{formatCustom(balanceFormat, "0,0.00")}</span> TRX
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <NextStep
          text="Deposit"
          onClick={() => onTransfer(action.address, id, amount)}
          disabled={isDisabled}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DashboardModalDeposit;
