import PropTypes from "prop-types";
// @mui
import { styled as styless } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
import Iconify from "./iconify/Iconify";
import Logo from "./Logo";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import styled from "styled-components";
import { useContext } from "react";
import { TronContext } from "contexts/TronContext";
import { formatStringAddress } from "helpers/format/formatStringAddress";

// utils

//
// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styless(AppBar)(({ theme }) => ({
  //   ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  background: theme.palette.background.paper,
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styless(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));
const StyledLogo = styless(Box)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    // display: "none",
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const { action } = useContext(TronContext);
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <StyledLogo>
            <WrapAccount>
              <div className="account-icon">
                <Person2OutlinedIcon />
              </div>
              <div className="account-address">
                <Typography>
                  {formatStringAddress(action.address, 6)}
                </Typography>
              </div>
            </WrapAccount>
          </StyledLogo>
          {/* <LanguagePopover />
          <NotificationsPopover />
          <AccountPopover /> */}
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
const WrapAccount = styled.div`
  display: flex;
  align-items: center;
  .account-icon {
    margin-right: 8px;
  }
`;
