import React, { createContext, useCallback, useState } from 'react'
export const ReloadContext = createContext({
    isReload: false,
    onReload: () => { }
})
const ReloadContextWrap = ({ children }: any) => {
    const [isReload, setIsReload] = useState(false)
    const onReload = useCallback(() => {
        setIsReload(!isReload)
    }, [isReload])
    return (
        <ReloadContext.Provider value={{
            isReload,
            onReload
        }}>
            {children}
        </ReloadContext.Provider>
    )
}

export default ReloadContextWrap