import DashboardLayout from 'layouts/nav/NavHeader';
import AccountPage from 'pages/AccountPage';
import DashboardApp from 'pages/DashboardPage';
import DetailsDappPage from 'pages/DetailsDappPage';
import Homepage from 'pages/Homepage';
import LoginPage from 'pages/LoginPage';
import { Navigate, useRoutes } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function Router() {
    const routes = useRoutes([
        {
            path: '/login',
            element: <LoginPage />
        },
        {
            element: <DashboardLayout />,
            children: [
                { element: <Navigate to="/dashboard/app" />, index: true },
                { path: '', element: <Homepage /> },
                { path: 'account', element: <AccountPage /> },
            ],
        },
        {
            path: '/dashboard',
            element: <DashboardLayout />,
            children: [
                { element: <Navigate to="/dashboard/app" />, index: true },
                { path: 'app', element: <DashboardApp /> },
                {
                    path: 'details/:id',
                    element: <DetailsDappPage />,
                },
            ],
        },
        // {
        //     path: 'account',
        //     element: <AccountPage />
        // },

    ]);

    return routes;
}
