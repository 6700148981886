import { TronContext } from 'contexts/TronContext'
import { formatSunToTRX } from 'helpers/format/formatNumber'
import React, { useContext, useEffect, useState } from 'react'

const useBalanceTrx = () => {
    const { action } = useContext(TronContext)
    const [balance, setBalance] = useState(0)
    useEffect(() => {
        if (action.address && (window as any).tronWeb) {
            (async () => {
                await (window as any).tronWeb.trx.getBalance(action.address).then((balance) => { setBalance(formatSunToTRX(balance)) })
            })()
        }
    }, [action.address, (window as any).tronWeb])
    return { balance }
}

export default useBalanceTrx