import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { MUTATION_EDIT_WHITELIST_CONTRACT } from "api/schema";
import NextStep from "components/core/ButtonStep";
import { ReloadContext } from "contexts/ReloadContext";
import { convertMethodAbi } from "helpers/format/convertMethodAbi";
import { notifyToastify } from "helpers/toastNotify";
import React, { useCallback, useContext, useState } from "react";
interface IDetailDappSettingEdit {
  apiKey: string;
  open: boolean;
  handleClose: () => void;
  contractId: string;
  abi: string;
  contractAddress?: string;
  initMethods: string[];
  initIsEnabled: boolean;
}
const CONVERT_BOOLEAN = { true: true, false: false };
const DetailDappSettingModalEdit = ({
  apiKey,
  open,
  handleClose,
  contractId,
  abi,
  contractAddress = "",
  initMethods,
  initIsEnabled = false,
}: IDetailDappSettingEdit) => {
  const listMethod = convertMethodAbi(abi);
  const { onReload } = useContext(ReloadContext);
  const [onEditWhitelistContract] = useMutation(
    MUTATION_EDIT_WHITELIST_CONTRACT,
    {
      onCompleted: () => {
        handleClose();
        onReload();
        notifyToastify({
          type: "success",
          text: "Edited whitelist successfully",
        });
      },
      onError: (error) => {
        console.log({ error });
        // notifyToastify({ type: "error", text: splitError(error) })
      },
    }
  );
  interface IHandleEditWhitelist {
    apiKey: string;
    contractId: string;
    methods: string[];
    isEnableWhitelistMethod: boolean;
  }
  const handleEditWhitelist = ({
    apiKey,
    contractId,
    methods,
    isEnableWhitelistMethod,
  }: IHandleEditWhitelist) => {
    try {
      onEditWhitelistContract({
        variables: { apiKey, contractId, methods, isEnableWhitelistMethod },
      });
    } catch (err) {
      console.log("ERROR EDIT WHITELIST", err);
    }
  };
  const [isEnabledMethod, setIsEnableMethod] = useState(
    initIsEnabled ? "true" : "false"
  );
  const handleChangeEnabledMethod = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsEnableMethod((event.target as HTMLInputElement).value);
  };
  const [methods, setMethods] = React.useState<string[]>(initMethods);

  const handleChange = (event: SelectChangeEvent<typeof methods>) => {
    const {
      target: { value },
    } = event;
    setMethods(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const theme = useTheme();
  function getStyles(name: string, personName: string[], theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ padding: 2 }}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle
        id="alert-dialog-title"
        justifyContent={"center"}
        //  fontSize={"2.5rem"}
      >
        Edit method contract
      </DialogTitle>
      <DialogContent>
        <Grid container borderRadius={2} spacing={2}>
          <Grid item xs={12}>
            <Typography>API KEY:</Typography>
            <Box mt={1} />
            <TextField
              type={"text"}
              variant="outlined"
              fullWidth
              value={apiKey}
              disabled
              placeholder="Enter contract ID"
            />
            {/* <Box mt={2} />
                        <Typography>Contract name:</Typography>
                        <Box mt={1} />
                        <TextField
                            type={"text"}
                            variant="outlined"
                            required
                            fullWidth
                            name='contractName'
                        /> */}
            <Box mt={2} />
            <Typography>Method name:</Typography>
            <Box mt={1} />
            <FormControl fullWidth>
              <Select
                // labelId="demo-multiple-name-label"
                // id="demo-multiple-name"
                multiple
                value={methods}
                onChange={handleChange}
                // input={<OutlinedInput label="Name" />}
                // MenuProps={MenuProps}
              >
                {listMethod.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, methods, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box mt={2} />
            <FormControl>
              <Typography>Enable whitelist method:</Typography>
              <Box mt={1} />
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={isEnabledMethod}
                onChange={handleChangeEnabledMethod}
              >
                <Grid>
                  <FormControlLabel
                    value={"true"}
                    control={<Radio />}
                    label="Enabled"
                  />
                  <FormControlLabel
                    value={"false"}
                    control={<Radio />}
                    label="Disabled"
                  />
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          {/* <Grid item xs={6}>
                        <Typography>Contract abi:</Typography>
                        <Box mt={1} />

                        <TextField
                            type={"text"}
                            variant="outlined"
                            required
                            fullWidth
                            name='contractAbi'
                            value={contractAbi}
                            onChange={onChange}
                            placeholder="Enter contract ID"
                            multiline
                            rows={10}
                        />
                    </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <NextStep onClick={handleClose} background="gray" text="Close" />

        <NextStep
          onClick={() =>
            handleEditWhitelist({
              apiKey,
              contractId,
              methods,
              isEnableWhitelistMethod: CONVERT_BOOLEAN[isEnabledMethod],
            })
          }
          text="Edit"
        />
      </DialogActions>
    </Dialog>
  );
};

export default DetailDappSettingModalEdit;
