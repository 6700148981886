import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { MUTATION_SETTING_PAYMENT } from "api/schema";
import React, { useContext, useEffect, useMemo, useState } from "react";
import TronWeb from "tronweb";
import LoadingButton from "@mui/lab/LoadingButton";
import PercentIcon from "@mui/icons-material/Percent";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styled from "styled-components";
import { notifyToastify } from "helpers/toastNotify";
import { TronContext } from "contexts/TronContext";
import { formatToken } from "helpers/format/formatToken";
import { configColor } from "constanst/configColor";
import NextStep from "components/core/ButtonStep";
interface PaymentSettingProps {
  apiKey: string;
  initialAddressToken: string;
  initialAddressReceiver: string;
  initialExchangeRate: number;
  initDecimals: number;
  initUserPayPercent: number;
}
const PaymentSetting = ({
  apiKey,
  initialAddressToken = "",
  initialAddressReceiver = "",
  initialExchangeRate = 1,
  initDecimals = 6,
  initUserPayPercent = 10,
}: PaymentSettingProps) => {
  const { tronWeb } = useContext(TronContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorToken, setIsErrorToken] = useState(false);
  const initInfoPayment = {
    percentShare: initUserPayPercent,
    addressToken: initialAddressToken,
    exchangeRate: formatToken({
      type: "devided",
      value: initialExchangeRate,
      length: 6,
    }),
    addressReceive: initialAddressReceiver,
    decimalsToken: 6,
  };
  const [infoPayment, setInfoPayment] = useState(initInfoPayment);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInfoPayment({ ...infoPayment, [e.target.name]: e.target.value });
  };
  const {
    percentShare,
    addressReceive,
    addressToken,
    exchangeRate,
    decimalsToken,
  } = infoPayment;
  const [onPaymentSetting, { loading }] = useMutation(MUTATION_SETTING_PAYMENT);
  const onUpdatePayment = () => {
    if (isErrorToken) {
      return;
    }
    try {
      onPaymentSetting({
        variables: {
          apiKey,
          receiverAddress: addressReceive,
          currencyAddress: addressToken,
          userPaymentPercent: +percentShare,
          currencyPrice:
            formatToken({
              type: "multiplied",
              value: exchangeRate,
              length: 6,
            }) + "",
          currencyDecimal: +decimalsToken,
          tokenName: nameToken,
        },
      }).then(
        () => {
          notifyToastify({
            type: "success",
            text: "Update payment successfully",
          });
        },
        (error) => {
          console.log("ERROR PAYMENT SETTINGS", error);
        }
      );
    } catch (error) {
      console.log("ERROR PAYMENT SETTINGS", error);
    }
  };
  const [abiToken, setAbiToken] = useState([]);
  const [nameToken, setNameToken] = useState("");
  useEffect(() => {
    if (TronWeb.isAddress(addressToken)) {
      (async function () {
        await tronWeb.trx?.getContract(addressToken).then((info: any) => {
          const abi = info?.abi?.entrys ?? [];
          const name = info?.name ?? "";
          if (name) {
            setNameToken(name);
          }
          if (abi.length > 0) {
            setAbiToken(abi);
          }
          // if (info?.abi) {
          //   setIsErrorToken(false);
          //   return;
          // }
          // setIsErrorToken(true);
        });
      })();
    } else {
      setIsErrorToken(true);
    }
  }, [addressToken, initialAddressToken]);
  useEffect(() => {
    if (abiToken.length > 0) {
      const getDecimals = async () => {
        const instance = await (window as any).tronWeb.contract(
          abiToken,
          addressToken
        );
        if (!!instance?.["decimals"] && instance?.["totalSupply"]) {
          const decimals = (await instance?.decimals().call()) || "";
          if (decimals) {
            setInfoPayment({ ...infoPayment, decimalsToken: +decimals });
            setIsErrorToken(false);
          }
        } else {
          setIsErrorToken(true);
        }
      };
      getDecimals();
    }
  }, [abiToken]);
  return (
    <Grid container>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <LabelTitle>
            <Typography>
              Address token TRC20:
              <Tooltip
                title="TRC20 token contract address."
                className="tooltip-label"
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
              <span
                style={{
                  color: configColor.green,
                  fontWeight: "bold",
                  paddingLeft: "5px",
                }}
              >
                {nameToken ? `(${nameToken})` : ""}
              </span>
            </Typography>
          </LabelTitle>
          <Box mt={1} />
          <TextField
            type={"text"}
            variant="outlined"
            required
            fullWidth
            name="addressToken"
            value={addressToken}
            onChange={onChange}
            placeholder="Enter address TRC20"
            error={addressToken !== "" && isErrorToken ? true : false}
            helperText={
              addressToken !== "" && isErrorToken
                ? "Contract address not correct"
                : false
            }
          />
          <Box mt={3} />
          <LabelTitle>
            <Typography style={{ display: "flex", alignItems: "center" }}>
              Pecent share{" "}
              <Tooltip
                title="The fee percentage charged to the user on each transaction. The actual transaction fee amount (in TRX) will be converted to TRC20 tokens and collected at this rate"
                className="tooltip-label"
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </Typography>
          </LabelTitle>
          <Box mt={1} />
          <TextField
            type={"number"}
            variant="outlined"
            required
            fullWidth
            name="percentShare"
            value={percentShare}
            onChange={onChange}
            placeholder="% share"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
            // error={percentShare !== 0 && percentShare > 100 ? true : false}
            // helperText={
            //   percentShare !== 0 && percentShare > 100
            //     ? "Exchange rate must be smaller than 100"
            //     : false
            // }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <LabelTitle>
            <Typography style={{ display: "flex", alignItems: "center" }}>
              Rate token
              <Tooltip
                title="The token exchange rate calculated in TRX. Token rate = Token Price / TRX Price."
                className="tooltip-label"
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </Typography>
          </LabelTitle>
          <Box mt={1} />
          <TextField
            type={"number"}
            variant="outlined"
            required
            fullWidth
            name="exchangeRate"
            value={exchangeRate}
            onChange={onChange}
            placeholder="Exchange rate"
            error={+exchangeRate === 0 ? true : false}
            helperText={
              +exchangeRate === 0
                ? "Exchange rate must be bigger than 0"
                : false
            }
          />
          <Box mt={3} />
          <LabelTitle>
            <Typography style={{ display: "flex", alignItems: "center" }}>
              Address receiver{" "}
              <Tooltip
                title="The recipient address for user's token instead of transaction fee"
                className="tooltip-label"
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </Typography>
          </LabelTitle>
          <Box mt={1} />
          <TextField
            type={"text"}
            variant="outlined"
            required
            fullWidth
            name="addressReceive"
            value={addressReceive}
            onChange={onChange}
            placeholder="Enter address receiver"
            error={
              addressReceive !== "" && !TronWeb.isAddress(addressReceive)
                ? true
                : false
            }
            helperText={
              addressReceive !== "" && !TronWeb.isAddress(addressReceive)
                ? "Address not correct"
                : false
            }
          />
        </Grid>
        <Grid container xs={12} mt={4} justifyContent="center">
          {/* <LoadingButton
            variant="contained"
            sx={{ px: 8 }}
            onClick={() => onUpdatePayment()}
            loading={loading}
            disabled={
              isErrorToken ||
              +exchangeRate === 0 ||
              !TronWeb.isAddress(addressReceive)
            }
          >
            Update
          </LoadingButton> */}
          <NextStep
            text="Update"
            onClick={() => onUpdatePayment()}
            isLoading={loading}
            disabled={
              isErrorToken ||
              +exchangeRate === 0 ||
              !TronWeb.isAddress(addressReceive)
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentSetting;
const LabelTitle = styled.div`
  position: relative;
  .tooltip-label {
    transform: translateY(0);
    margin-left: 3px;
  }
`;
