import { gql } from "@apollo/client";

//mutations
export const MUTATION_LOGIN = gql`
  mutation setLogin(
    $address: String!
    $timestamp: Float!
    $signature: String!
    $duration_sec: Int
  ) {
    rls_login(
      address: $address
      timestamp: $timestamp
      signature: $signature
      duration_sec: $duration_sec
    )
  }
`;

export const MUTATION_ADD_PROJECT = gql`
  mutation setAddProject($projectName: String!) {
    rls_user_add_project(project_name: $projectName)
  }
`;
export const MUTATION_TRANSFER_BALANCE = gql`
  mutation setTransferBalance($from: String!, $to: String!, $amount: Float!) {
    rls_user_transfer_balance(from: $from, to: $to, amount: $amount)
  }
`;
export const MUTATION_DELETE_PROJECT = gql`
  mutation setDeleteProject($apiKey: String!) {
    rls_user_delete_project(api_key: $apiKey)
  }
`;
export const MUTATION_ADD_WHITELIST_CONTRACT = gql`
  mutation mutationAddProjectWhitelist(
    $apiKey: String!
    $contractName: String!
    $contractAddress: String!
    $contractAbi: String!
  ) {
    rls_user_add_project_whitelist_contract(
      api_key: $apiKey
      contract_name: $contractName
      contract_address: $contractAddress
      contract_abi: $contractAbi
    )
  }
`;
export const MUTATION_DETETE_WHITELIST_CONTRACT = gql`
  mutation mutationDeleProjectWhitelist(
    $apiKey: String!
    $contractId: String!
  ) {
    rls_user_delete_project_whitelist_contract(
      api_key: $apiKey
      contract_id: $contractId
    )
  }
`;
export const MUTATION_EDIT_WHITELIST_CONTRACT = gql`
  mutation mutationEditProjectWhitelist(
    $apiKey: String!
    $contractId: String!
    $methods: [String]
    $isEnableWhitelistMethod: Boolean
  ) {
    rls_user_edit_project_whitelist_contract(
      api_key: $apiKey
      contract_id: $contractId
      allowed_methods: $methods
      enable_whitelist_methods: $isEnableWhitelistMethod
    )
  }
`;
//querys
export const QUERY_USER_INFO = gql`
  query {
    rls_user_info_get {
      address
      last_login
      balance
      total_deposit_amount
      total_withdraw_amount
    }
  }
`;
export const QUERY_USER_ACTIVITY = gql`
  query getUserActivity($page: Int, $pageSize: Int) {
    rls_user_activity_history(page: $page, pageSize: $pageSize) {
      total
      data {
        from
        to
        type
        amount
        txid
        reason
        created_at
      }
    }
  }
`;
export const QUERY_GET_INFO_DEPOSIT = gql`
  query getDepositInfo {
    rls_user_deposit_info_get {
      deposit_address
    }
  }
`;
export const QUERY_USER_PROJECTS = gql`
  query getUserProject($page: Int, $pageSize: Int) {
    rls_user_projects_get(page: $page, pageSize: $pageSize) {
      total
      data {
        api_key
        payment_address
        balance
        project_name
      }
    }
  }
`;
export const QUERY_PROJECT_INFO = gql`
  query getProjectInfo($apiKey: String!) {
    rls_user_project_info_get(api_key: $apiKey) {
      api_key
      payment_address
      balance
      project_name
      total_deposit_amount
      total_paid
      total_savings
      total_fail_tx
      total_actual_fee
      total_success_tx
      total_debit_amount
      recipient_contracts {
        id
      }
      currency_address
      receiver_address
      currency_price_in_sun
      currency_decimal
      user_pay_percent
    }
  }
`;
export const QUERY_PROJECT_ACTIVITY = gql`
  query getUserActivity($page: Int, $pageSize: Int, $apiKey: String!) {
    rls_user_project_activity_history(
      page: $page
      pageSize: $pageSize
      api_key: $apiKey
    ) {
      total
      data {
        from
        to
        type
        amount
        reason
        created_at
        cod_token_name
        cod_token_amount
        cod_token_decimal
        txid
      }
    }
  }
`;
export const QUERY_SETTINGS_CONTRACT = gql`
  query getProjectInfo($apiKey: String!) {
    rls_user_project_info_get(api_key: $apiKey) {
      recipient_contracts {
        id
        name
        enable_whitelist_methods
        address
        methods_allowed
        abi
      }
    }
  }
`;
export const MUTATION_SETTING_PAYMENT = gql`
  mutation mutationSettingPayment(
    $apiKey: String!
    $receiverAddress: String
    $currencyAddress: String
    $currencyPrice: String
    $userPaymentPercent: Int
    $currencyDecimal: Int
    $tokenName: String
  ) {
    rls_user_edit_project_currency(
      api_key: $apiKey
      receiver_address: $receiverAddress
      currency_address: $currencyAddress
      currency_price_in_sun: $currencyPrice
      user_pay_percent: $userPaymentPercent
      currency_decimal: $currencyDecimal
      currency_name: $tokenName
    )
  }
`;
