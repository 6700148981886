
// export const convertMethodAbi = (abi) => {
//     const list_write_functions = [] as any
//     console.log('abi', typeof abi, typeof JSON.parse(abi))
//     for (let item of JSON.parse(abi)) {
//         // console.log({ item })
//         // if (item.name && ["function"].includes(item.type) && !["view", "pure"].includes(item.stateMutability)) {
//         //     list_write_functions.push(item.name)
//         // }
//     }
//     return list_write_functions
// }

export const convertMethodAbi = (abi) => {
    // console.log("abi convert", JSON.parse(abi))
    const list_write_functions = [] as any
    if (abi?.startsWith("[{")) {
        const splitAbi = JSON.parse(abi)
        for (let item of splitAbi) {
            if (item.name && ["Function", "function"].includes(item.type) && !["View", "Pure", "view", "pure"].includes(item.stateMutability)) {
                list_write_functions.push(item.name)
            }
        }
    }
    return list_write_functions
}
