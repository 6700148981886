import React, { createContext, useEffect, useMemo, useState } from "react";
import TronWeb from "tronweb";

const fullNode = process.env.REACT_APP_NODE_TRON;
const solidityNode = process.env.REACT_APP_NODE_TRON;
const eventServer = process.env.REACT_APP_EVENT_TRON;
if (!fullNode || !solidityNode || !eventServer) {
  throw new Error("Please config node network");
}
const mainOptions = {
  fullNode,
  solidityNode,
  eventServer,
};
const tronWeb = new TronWeb({
  fullNode: mainOptions.fullNode,
  solidityNode: mainOptions.solidityNode,
  privateKey: "",
  eventServer: mainOptions.eventServer,
});

// eslint-disable-next-line global-require
window.Buffer = window.Buffer || require("buffer").Buffer;

export const TronContext = createContext({
  onConnect: () => {},
  isConnected: true,
  action: {
    address: "",
    error: "",
  },
  tronWeb,
});

const TronContextWrap = ({ children }) => {
  const [action, setAction] = useState({
    address: "",
    error: "",
  });
  const eventConnect = async () =>
    new Promise((res) => {
      let attempts = 0;
      // eslint-disable-next-line prefer-const
      let maxAttempts = 20;
      const checkTron = () => {
        if (
          (window as any).tronWeb &&
          (window as any).tronWeb.defaultAddress.base58
        ) {
          res(true);
          return;
        }
        // eslint-disable-next-line no-plusplus
        attempts++;
        if (attempts >= maxAttempts) {
          res(false);
          return;
        }
        setTimeout(checkTron, 100);
      };
      checkTron();
    });
  const onConnect = async () => {
    let tronExist = await eventConnect();
    if (tronExist) {
      const address = (window as any).tronWeb.defaultAddress.base58;
      if (address) {
        setAction({
          ...action,
          address,
        });
      }
    } else {
      setAction({
        ...action,
        address: "",
      });
    }
  };
  useEffect(() => {
    onConnect();
  }, [(window as any).tronWeb]);
  //check TRONLINK account
  useEffect(() => {
    function getData(e: any) {
      //hanlde account login + switch
      if (e.data.message && e.data.message.action === "accountsChanged") {
        if (e.data?.message.data.address) {
          setAction({ ...action, address: e.data.message.data.address });
          return;
        }
        setAction({ ...action, address: "" });
      }
      //handle change node network
      if (e.data.message && e.data.message.action === "setNode") {
        if (
          e.data?.message.data.node.fullNode !== fullNode ||
          e.data?.message.data.node.eventServer !== eventServer
        ) {
          setAction({ ...action, error: "node" });
        }
      }
    }
    window.addEventListener("message", (e) => getData(e));
    return () => {
      window.removeEventListener("message", (e) => getData(e));
    };
  }, []);
  const isConnected = useMemo(() => {
    if (action.address) {
      return true;
    }
    return false;
  }, [action.address]);
  return (
    <TronContext.Provider
      value={{
        onConnect,
        action,
        isConnected,
        tronWeb,
      }}
    >
      {children}
    </TronContext.Provider>
  );
};

export default TronContextWrap;
