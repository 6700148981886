import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { QUERY_USER_ACTIVITY } from "api/schema";
import { ReloadContext } from "contexts/ReloadContext";
import { TronContext } from "contexts/TronContext";
import dayjs from "dayjs";
import { formatCustom, formatSunToTRX } from "helpers/format/formatNumber";
import { formatStringAddress } from "helpers/format/formatStringAddress";
import React, { useContext, useEffect, useMemo } from "react";

interface Column {
  id: "date" | "type" | "reason" | "amount" | "from" | "to" | "txid";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any) => any;
}

const AccountTableActivity = () => {
  const { action } = useContext(TronContext);
  const { isReload } = useContext(ReloadContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [getUserActivity, { data, loading, error }] = useLazyQuery(
    QUERY_USER_ACTIVITY,
    { variables: { page, pageSize: rowsPerPage } }
  );
  const rows = useMemo(() => {
    if (!data) {
      return [];
    }
    if (data && data.rls_user_activity_history) {
      const { data: dataHistory } = data.rls_user_activity_history;
      if (dataHistory) {
        const dataMapping = dataHistory.map((activity) => {
          const {
            from,
            to,
            type,
            amount,
            txid,
            reason,
            created_at: date,
          } = activity;
          let amountFormat = amount;
          switch (type) {
            case "transfer":
              if (from === action.address) {
                amountFormat = -amount;
              } else {
                amountFormat = amount;
              }
              break;

            default:
              amountFormat = amount;
              break;
          }

          return {
            date: dayjs(date).valueOf(),
            type,
            reason,
            amount: amountFormat,
            from,
            to,
            txid,
          };
        });
        return dataMapping;
      }
    }
  }, [data]);
  const totalItems = useMemo(() => {
    if (data && data.rls_user_activity_history) {
      const { total } = data.rls_user_activity_history;
      return total;
    }
    return 0;
  }, [data]);
  useEffect(() => {
    getUserActivity({
      variables: { page, pageSize: rowsPerPage },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });
  }, [page, rowsPerPage, isReload]);
  const columns: Column[] = useMemo(
    () => [
      {
        id: "date",
        label: "Date",
        minWidth: 170,
        format: (value: number) => dayjs(value).format("HH:mm - DD/MM/YYYY"),
      },
      {
        id: "type",
        label: "Type",
        minWidth: 100,
        format: (value: string) => (
          <Typography typography={"table"} textTransform={"capitalize"}>
            {value}
          </Typography>
        ),
      },
      {
        id: "reason",
        label: "Reason",
        minWidth: 170,
        format: (value: string) => (
          <Typography typography={"table"} textTransform={"capitalize"}>
            {value}
          </Typography>
        ),
      },
      {
        id: "amount",
        label: "Amount",
        minWidth: 100,

        format: (value: number) => (
          <Typography color={value >= 0 ? "green" : "#EA2B2B"}>
            {formatCustom(formatSunToTRX(value), "0,0.00")}
          </Typography>
        ),
      },
      {
        id: "from",
        label: "From",
        format: (value: string) => formatStringAddress(value, 4),
      },
      {
        id: "to",
        label: "To",
        format: (value: string) => {
          if (action.address === value) {
            return "My Account";
          }
          if (value === "system") {
            return "System";
          }
          return formatStringAddress(value, 8);
        },
      },
      {
        id: "txid",
        label: "TXID",
        // align: 'right',
        format: (value: string) =>
          value ? formatStringAddress(value, 6) : null,
      },
    ],
    [action.address]
  );
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default AccountTableActivity;
