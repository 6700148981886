import { useLazyQuery, useQuery } from "@apollo/client";
import { Alert, Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { QUERY_PROJECT_INFO } from "api/schema";
import { ReloadContext } from "contexts/ReloadContext";
import { formatCustom, formatSunToTRX } from "helpers/format/formatNumber";
import { formatStringAddress } from "helpers/format/formatStringAddress";
import React, { useContext, useEffect, useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useParams } from "react-router-dom";
import DetailDappActivity from "sections/details-dapp/DetailDappActivity";
import DetailDappTransfer from "sections/details-dapp/DetailDappTransfer";
import styled from "styled-components";
import { IDapp } from "types";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DetailModalSettings from "sections/details-dapp/DetailModalSettings";
import DetailDappSettings from "sections/details-dapp/DetailDappSettings";
import PaymentSetting from "sections/details-dapp/payment/PaymentSetting";
import NextStep from "components/core/ButtonStep";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

const DetailsDappPage = () => {
  const { isReload } = useContext(ReloadContext);
  const { id } = useParams();
  const [getProjectInfo, { data, loading }] = useLazyQuery(QUERY_PROJECT_INFO);
  useEffect(() => {
    if (id) {
      getProjectInfo({
        variables: { apiKey: id },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
      });
    }
  }, [id, isReload]);
  const info: IDapp = useMemo(() => {
    if (data && data.rls_user_project_info_get) {
      const {
        api_key: apiKey,
        payment_address: paymentAddress,
        balance,
        project_name: name,
        total_deposit_amount: totalDeposit,
        total_debit_amount: totalDebit,
        total_actual_fee: totalActualFee,
        total_savings: totalSaving,
        total_paid: totalPaid,
        total_success_tx: totalSuccessTx,
        total_fail_tx: totalFailTx,
        recipient_contracts: recipientContracts,
        currency_address: addressToken,
        receiver_address: addressReceiver,
        currency_price_in_sun: exchangeRate,
        currency_decimal: tokenDecimals,
        user_pay_percent: userPayPercent,
      } = data.rls_user_project_info_get;
      return {
        apiKey,
        paymentAddress,
        balance: formatSunToTRX(balance),
        name,
        totalDeposit: formatSunToTRX(totalDeposit),
        totalDebit: formatSunToTRX(totalDebit),
        totalActualFee: formatSunToTRX(totalActualFee),
        totalSaving: formatSunToTRX(totalSaving),
        totalPaid: formatSunToTRX(totalPaid),
        totalSuccessTx,
        totalFailTx,
        recipientContracts: recipientContracts ?? [],
        addressToken,
        addressReceiver,
        exchangeRate,
        tokenDecimals,
        userPayPercent,
      };
    }
    return {
      apiKey: "",
      paymentAddress: "",
      balance: 0,
      name: "",
      totalDeposit: 0,
      totalDebit: 0,
      totalActualFee: 0,
      totalSaving: 0,
      totalPaid: 0,
      totalSuccessTx: 0,
      totalFailTx: 0,
      recipientContracts: [],
      addressToken: "",
      addressReceiver: "",
      exchangeRate: 1,
      tokenDecimals: 6,
      userPayPercent: 10,
    };
  }, [data]);
  const {
    balance,
    apiKey,
    paymentAddress,
    name,
    totalDeposit,
    totalDebit,
    totalActualFee,
    totalSaving,
    totalFailTx,
    totalPaid,
    totalSuccessTx,
    recipientContracts,
    addressToken,
    addressReceiver,
    exchangeRate,
    tokenDecimals,
    userPayPercent,
  } = info;
  const [isCopy, setIsCopy] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isCopy) {
        setIsCopy(false);
      }
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [isCopy]);
  // settings
  const [isSetting, setIsSetting] = useState(false);
  const handleSettings = (e: boolean) => {
    setIsSetting(e);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <>
        <Container maxWidth="xl">
          <Grid container paddingY={{ lg: 3 }} paddingX={{ lg: 6, xs: 0 }}>
            {recipientContracts.length > 0 ? null : (
              <Grid container mb={2}>
                <Grid item xs={12}>
                  <Alert severity="warning">
                    If you aren't setting a whitelist, some features won't
                    working.
                    <span
                      style={{ color: "#2065D1", cursor: "pointer" }}
                      onClick={() => setValue(2)}
                    >
                      {" "}
                      Settings now
                    </span>
                  </Alert>
                </Grid>
              </Grid>
            )}
            <Typography variant="h5" mb={3}>
              {" "}
              {id}
            </Typography>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Overview" {...a11yProps(0)} />
                  <Tab label="Activity" {...a11yProps(1)} />
                  <Tab label="Settings" {...a11yProps(2)} />
                  <Tab label="Payment" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Grid className="layout-grid" container>
                  <Grid container>
                    <Grid item lg={6} py={2} xs={12}>
                      <Typography>
                        Project name:{" "}
                        <span style={{ fontWeight: 700 }}>{name}</span>
                      </Typography>
                    </Grid>
                    <Grid item lg={6} py={2} xs={12}>
                      <Typography typography={"span"} marginRight={3}>
                        API Key: <span style={{ fontWeight: 700 }}> </span>
                      </Typography>
                      <CopyToClipboard
                        text={apiKey}
                        onCopy={() => setIsCopy(true)}
                      >
                        <Button
                          variant="contained"
                          endIcon={
                            !isCopy ? (
                              <ContentCopyOutlinedIcon />
                            ) : (
                              <CheckOutlinedIcon />
                            )
                          }
                        >
                          {!isCopy ? "Copy" : "Copied"}
                        </Button>
                        {/* <NextStep
                          size="small"
                          text={!isCopy ? "Copy" : "Copied"}
                        /> */}
                      </CopyToClipboard>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={6} py={2} xs={12}>
                      <Typography>
                        Payment address:{" "}
                        <span style={{ fontWeight: 700 }}>
                          {formatStringAddress(paymentAddress, 7)}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item lg={6} py={2} xs={12}>
                      <Typography>
                        Balance:{" "}
                        <span style={{ fontWeight: 700 }}>
                          {formatCustom(balance, "0,0.000")} TRX
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={6} py={2} xs={12}>
                      <Typography>
                        Total Deposit amount:{" "}
                        <span style={{ fontWeight: 700 }}>
                          {formatCustom(totalDeposit, "0,0.000")} TRX
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item lg={6} py={2} xs={12}>
                      <Typography>
                        Total debit amount:{" "}
                        <span style={{ fontWeight: 700 }}>
                          {formatCustom(totalDebit, "0,0.000")} TRX
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={6} py={2} xs={12}>
                      <Typography>
                        Total actual fee:{" "}
                        <span style={{ fontWeight: 700 }}>
                          {formatCustom(totalActualFee, "0,0.000")} TRX
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item lg={6} py={2} xs={12}>
                      <Typography>
                        Total savings:{" "}
                        <span style={{ fontWeight: 700 }}>
                          {formatCustom(totalSaving, "0,0.000")} TRX
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={6} py={2} xs={12}>
                      <Typography>
                        Total Paid:{" "}
                        <span style={{ fontWeight: 700 }}>
                          {formatCustom(totalPaid, "0,0.000")} TRX
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={6} py={2} xs={12}>
                      <Typography>
                        Total transaction success:{" "}
                        <span style={{ fontWeight: 700 }}>
                          {formatCustom(totalSuccessTx, "0,0")} transaction
                          {totalSuccessTx > 1 ? "s" : ""}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item lg={6} py={2} xs={12}>
                      <Typography>
                        Total transaction fail:{" "}
                        <span style={{ fontWeight: 700 }}>
                          {formatCustom(totalFailTx, "0,0")} transaction
                          {totalSuccessTx > 1 ? "s" : ""}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid className="layout-grid" container>
                  {id ? <DetailDappActivity apiKey={id} /> : null}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                {id ? <DetailDappSettings apiKey={id} /> : null}
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Grid container className="layout-grid">
                  {id ? (
                    <PaymentSetting
                      apiKey={id}
                      initialAddressReceiver={addressReceiver}
                      initialAddressToken={addressToken}
                      initialExchangeRate={exchangeRate}
                      initDecimals={tokenDecimals}
                      initUserPayPercent={userPayPercent}
                    />
                  ) : null}
                </Grid>
              </TabPanel>
            </Box>
          </Grid>
        </Container>
      </>
      {apiKey ? <DetailDappTransfer balance={balance} apiKey={apiKey} /> : null}
      {isSetting ? (
        <DetailModalSettings
          id={apiKey}
          handleClose={() => handleSettings(false)}
          open={isSetting}
        />
      ) : null}
    </>
  );
};

export default DetailsDappPage;
