import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { QUERY_PROJECT_ACTIVITY, QUERY_USER_ACTIVITY } from "api/schema";
import { configColor } from "constanst/configColor";
import { ReloadContext } from "contexts/ReloadContext";
import { TronContext } from "contexts/TronContext";
import dayjs from "dayjs";
import { formatCustom, formatSunToTRX } from "helpers/format/formatNumber";
import { formatStringAddress } from "helpers/format/formatStringAddress";
import { formatToken } from "helpers/format/formatToken";
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from "react";

interface Column {
  id:
    | "date"
    | "type"
    | "reason"
    | "amount"
    | "from"
    | "to"
    | "txid"
    | "addressToken"
    | "codAmount";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any) => any;
}
const TRON_EXPLORE = process.env.REACT_APP_EXPLORE_TRON ?? "";
const DetailDappActivity = ({ apiKey }: { apiKey: string }) => {
  const { action, tronWeb } = useContext(TronContext);
  const { isReload } = useContext(ReloadContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [getProjectActivity, { data, loading, error }] = useLazyQuery(
    QUERY_PROJECT_ACTIVITY
  );

  const [arrTokens, setArrTokens] = useState([]) as any;
  const [isEnd, startTransition] = useTransition();
  const rows = useMemo(() => {
    if (!data) {
      return [];
    }
    if (data && data.rls_user_project_activity_history) {
      const { data: dataHistory } = data.rls_user_project_activity_history;
      if (dataHistory) {
        const dataMapping = [...dataHistory].map((activity, i) => {
          const {
            from,
            to,
            type,
            amount,
            txid,
            reason,
            created_at: date,
            cod_token_name: tokenName,
            cod_token_amount: tokenAmount,
            cod_token_decimal: tokenDecimals,
          } = activity;
          let amountFormat = amount;
          let amountFormatCOD = amount;
          let newAmountCOD = formatToken({
            type: "devided",
            value: tokenAmount ?? 0,
            length: tokenDecimals ?? 6,
          });
          switch (type) {
            case "transfer":
              if (from === action.address) {
                amountFormat = amount;
              } else {
                amountFormat = -amount;
              }
              break;
            case "debit":
              amountFormat = -amount;
              break;
            case "credit":
              amountFormat = amount;
              break;
            default:
              amountFormat = amount;
              break;
          }
          switch (type) {
            case "transfer":
              if (from === action.address) {
                newAmountCOD = -newAmountCOD;
              } else {
                // newAmountCOD = newAmountCOD;
              }
              break;
            case "debit":
              // newAmountCOD = newAmountCOD;
              break;
            case "credit":
              newAmountCOD = -newAmountCOD;
              break;
            default:
              newAmountCOD = -newAmountCOD;
              break;
          }
          return {
            date: dayjs(date).valueOf(),
            type,
            reason,
            amount: { amountFormat, tokenName: "WTRX" },
            codAmount: {
              amountFormat: newAmountCOD,
              tokenName: tokenName ?? "WTRX",
            },
            from,
            to,
            txid,
          };
        }) as any;
        return dataMapping;
      }
    }
  }, [data]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      if (data && data.rls_user_project_activity_history) {
        const { data: dataHistory } = data.rls_user_project_activity_history;
        const getNameToken = async (address) => {
          setIsLoading(true);
          let nameToken = "";
          nameToken = await tronWeb.trx
            ?.getContract(address)
            .then((info: any) => {
              const name = info?.name ?? "";
              setIsLoading(false);
              if (name) {
                return name;
              }
            });

          return nameToken;
        };
        if (dataHistory) {
          const arrToken = [] as string[];
          [...dataHistory].forEach(async (item: any) => {
            const addressToken = "TUwoJa8LjD7z1yAD2VFrEoyFDKpco5Gmi8";
            await getNameToken(addressToken).then((name: string) => {
              setArrTokens(arrToken);
            });

            // startTransition(() => {
            //   setArrTokens([...arrTokens, nameToken || "TRX"]);
            // });
          });
          // console.log({ arrToken });
          if (arrToken.length === dataHistory.length) {
            startTransition(() => {
              setArrTokens(arrToken);
            });
          }
          // setArrTokens(arrToken);
        }
      }
    })();
  }, [data]);
  const totalItems = useMemo(() => {
    if (!data) {
      return 0;
    }
    if (data && data.rls_user_project_activity_history) {
      const { total } = data.rls_user_project_activity_history;
      return total;
    }
    return 0;
  }, [data]);
  useEffect(() => {
    getProjectActivity({
      variables: { page, pageSize: rowsPerPage, apiKey },
      // fetchPolicy: "no-cache"
      fetchPolicy: "network-only", // Used for first execution
      nextFetchPolicy: "cache-first", // Used for subsequent executions
    });
  }, [isReload, rowsPerPage, apiKey, page]);
  const columns: readonly Column[] = useMemo(
    () => [
      {
        id: "date",
        label: "Date",
        minWidth: 150,
        format: (value: number) => dayjs(value).format("HH:mm - DD/MM/YYYY"),
      },
      {
        id: "type",
        label: "Type",
        minWidth: 100,
        format: (value: string) => (
          <div>
            <Typography typography={"table"} textTransform={"capitalize"}>
              {value}
            </Typography>
          </div>
        ),
      },
      {
        id: "reason",
        label: "Reason",
        minWidth: 170,
        format: (value: string) => (
          <div>
            <Typography typography={"table"} textTransform={"capitalize"}>
              {value}
            </Typography>
          </div>
        ),
      },
      {
        id: "amount",
        label: "Amount",
        minWidth: 100,
        format: ({ amountFormat, tokenName }) => {
          return (
            <div>
              <Typography color={amountFormat >= 0 ? "green" : "red"}>
                {formatCustom(formatSunToTRX(amountFormat || ""))} {tokenName}
              </Typography>
            </div>
          );
        },
      },
      {
        id: "codAmount",
        label: "COD Amount",
        minWidth: 120,
        format: ({ amountFormat, tokenName }) => {
          if (!amountFormat) {
            return (
              <div>
                <Typography>--</Typography>
              </div>
            );
          }
          return (
            <div>
              <Typography color={amountFormat >= 0 ? "green" : "#EA2B2B"}>
                {formatCustom(amountFormat || "")} {tokenName}
              </Typography>
            </div>
          );
        },
      },
      {
        id: "from",
        label: "From",
        format: (value: string) => {
          if (action.address === value) {
            return "My Account";
          }
          if (value === "system") {
            return "System";
          }
          return formatStringAddress(value ?? "", 7);
        },
      },
      {
        id: "to",
        label: "To",
        // minWidth: 170,

        format: (value: string) => {
          return formatStringAddress(value ?? "", 7);
        },
      },
      {
        id: "txid",
        label: "Txid",
        // minWidth: 170,

        format: (value: string) => {
          if (!value) {
            return;
          }
          if (action.address === value) {
            return "My Account";
          }
          if (value === "system") {
            return "System";
          }
          return (
            <a
              target="_blank"
              href={`${TRON_EXPLORE}transaction/${value}`}
              rel="noreferrer"
              style={{ color: configColor.green }}
            >
              Detail
            </a>
          );
        },
      },
    ],
    [action.address]
  );
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DetailDappActivity;
