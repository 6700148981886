import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useContext } from 'react';
import { Cookies } from 'react-cookie';
import { TronContext } from './TronContext';

const linkApi = process.env.REACT_APP_API
const Apollo = ({ children }) => {
    const { action: { address } } = useContext(TronContext)
    const httpLink = createHttpLink({
        uri: linkApi,
    });

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        // const token = localStorage.getItem('token');
        // const token = cookies?.[`token-${address}`]
        const Cookie = new Cookies()
        const token = Cookie.get(`token-${address}`);
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? token : "",
            }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });

    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )
}

export default Apollo